var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sw-account"},[_c('general-information',{ref:"account-general-info",attrs:{"isEdit":_vm.isEdit,"generalInfo":_vm.info,"showSave":true},on:{"update:isEdit":function($event){_vm.isEdit=$event},"update:is-edit":function($event){_vm.isEdit=$event},"save":_vm.onSave}}),_c('div',{staticClass:"sw-account__password"},[_c('div',{staticClass:"sw-account__password--heading"},[_vm._m(0),_c('LxpButton',{attrs:{"variant":_vm.isChangePassword ? 'secondary1' : 'link-secondary1',"className":_vm.btnClassName},on:{"click":_vm.onChangePassword}},[(_vm.passwordLoadingStatus === 'LOADING')?_c('LxpLoader',{attrs:{"loaderClass":"sw-account__password--loader"}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.passwordContent)}})],1)],1),(_vm.isChangePassword)?_c('div',{staticClass:"sw-account__password--form"},[_c('ValidationObserver',{ref:"change-password-observer"},[_c('div',{staticClass:"sw-account__password--form__div"},[_c('ValidationProvider',{attrs:{"vid":"password","name":"New password","rules":{
              required: true,
              regex: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).*$/
            },"custom-messages":{
              regex:
                'Password must contain at least eight characters; one lowercase letter (a-z), one uppercase letter (A-Z), one number (0-9), and one special character (e.g @$*&...)'
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('LxpInput',{attrs:{"id":"new-password","type":"password","label":"New Password*","hasError":errors[0] ? true : false,"supportText":errors[0] ? errors[0] : ''},model:{value:(_vm.password.new_password),callback:function ($$v) {_vm.$set(_vm.password, "new_password", $$v)},expression:"password.new_password"}})]}}],null,false,3196466276)})],1),_c('div',{staticClass:"sw-account__password--form__div"},[_c('ValidationProvider',{attrs:{"name":"Confirm password","rules":"required|confirmed:password","custom-messages":{ confirmed: 'Passwords do not match' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('LxpInput',{attrs:{"id":"confirm-password","type":"password","label":"Confirm Password*","hasError":errors[0] ? true : false,"supportText":errors[0] ? errors[0] : ''},model:{value:(_vm.password.re_new_password),callback:function ($$v) {_vm.$set(_vm.password, "re_new_password", $$v)},expression:"password.re_new_password"}})]}}],null,false,2911513228)})],1)])],1):_c('p',[_vm._v(" To change your password, you will be asked to enter your current password. In case you don't remember it, you can log out and click the \"Forgot Password\" button, and instructions for changing your password will be sent to your email. ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('img',{attrs:{"src":require("@/assets/images/partner/settings/password-lock.svg"),"alt":""}}),_vm._v("Password ")])
}]

export { render, staticRenderFns }