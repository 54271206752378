<template>
  <div class="sw-preference">
    <div class="sw-preference__delete">
      <div class="sw-preference__delete--heading">
        <h6>
          <img
            src="@/assets/images/partner/settings/password-lock.svg"
            alt=""
          />Delete Account
        </h6>
        <LxpButton variant="link-danger" @click="showModal = true"
          >Delete</LxpButton
        >
      </div>
      <p>
        If you wish to delete your account, please click Delete. If you have any
        questions or require assistance, please don't hesitate to reach out to
        our support team.
      </p>
    </div>
    <lxp-modal
      :show.sync="showModal"
      id="delete-account-modal"
      centered
      title="Delete Account"
    >
      <p>
        Are you sure you want to delete your account? This action is permanent
        and cannot be reverted. Please confirm.
      </p>
      <template #footer>
        <div class="delete-account__footer">
          <lxp-button variant="outline-danger" @click="onDelete"
            >Delete</lxp-button
          >
          <lxp-button @click="showModal = false">Cancel</lxp-button>
        </div>
      </template>
    </lxp-modal>
  </div>
</template>

<script>
import { LxpButton, LxpModal } from "didactica";
export default {
  components: {
    LxpButton,
    LxpModal
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    onDelete() {
      this.$store.dispatch("deleteMyAccount").then(() => {
        this.showModal = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sw-preference {
  .sw-preference__delete {
    @include account-style();
    .sw-preference__delete--heading {
      @include horizontal-space-between;
      margin-bottom: 8px;
    }
    p {
      @include body-medium;
      color: $brand-neutral-700;
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss">
#delete-account-modal {
  p {
    @include body-regular;
    margin-bottom: 0;
    text-align: center;
  }
  .delete-account__footer {
    display: flex;
    justify-content: space-between;
    .btn-primary {
      width: 193px;
    }
  }
}
</style>
