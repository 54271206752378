<template>
  <div class="settings-wrapper">
    <b-tabs
      nav-class="justify-content-md-center justify-content-lg-start"
      pills
      content-class="mt-4"
      v-model="tabIndex"
      lazy
    >
      <b-tab title="Account" active>
        <Account />
      </b-tab>
      <b-tab title="Preferences">
        <Preferences />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Account from "./Settings/Account.vue";
import Preferences from "./Settings/Preferences.vue";
export default {
  components: {
    Account,
    Preferences
  },
  data() {
    return {
      tabIndex: 0
    };
  }
};
</script>

<style lang="scss">
@import "../../scss/tab.scss";
</style>
