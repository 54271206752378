<template>
  <div class="sw-account">
    <general-information
      :isEdit.sync="isEdit"
      :generalInfo="info"
      :showSave="true"
      @save="onSave"
      ref="account-general-info"
    />
    <div class="sw-account__password">
      <div class="sw-account__password--heading">
        <h6>
          <img
            src="@/assets/images/partner/settings/password-lock.svg"
            alt=""
          />Password
        </h6>
        <LxpButton
          :variant="isChangePassword ? 'secondary1' : 'link-secondary1'"
          @click="onChangePassword"
          :className="btnClassName"
          ><LxpLoader
            v-if="passwordLoadingStatus === 'LOADING'"
            loaderClass="sw-account__password--loader"/>
          <div v-else v-html="passwordContent"></div
        ></LxpButton>
      </div>
      <div class="sw-account__password--form" v-if="isChangePassword">
        <ValidationObserver ref="change-password-observer">
          <div class="sw-account__password--form__div">
            <ValidationProvider
              vid="password"
              name="New password"
              :rules="{
                required: true,
                regex: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).*$/
              }"
              v-slot="{ errors }"
              :custom-messages="{
                regex:
                  'Password must contain at least eight characters; one lowercase letter (a-z), one uppercase letter (A-Z), one number (0-9), and one special character (e.g @$*&...)'
              }"
            >
              <LxpInput
                id="new-password"
                type="password"
                label="New Password*"
                v-model="password.new_password"
                :hasError="errors[0] ? true : false"
                :supportText="errors[0] ? errors[0] : ''"
              ></LxpInput>
            </ValidationProvider>
          </div>
          <div class="sw-account__password--form__div">
            <ValidationProvider
              name="Confirm password"
              rules="required|confirmed:password"
              v-slot="{ errors }"
              :custom-messages="{ confirmed: 'Passwords do not match' }"
            >
              <LxpInput
                id="confirm-password"
                type="password"
                label="Confirm Password*"
                v-model="password.re_new_password"
                :hasError="errors[0] ? true : false"
                :supportText="errors[0] ? errors[0] : ''"
              ></LxpInput>
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </div>
      <p v-else>
        To change your password, you will be asked to enter your current
        password. In case you don't remember it, you can log out and click the
        "Forgot Password" button, and instructions for changing your password
        will be sent to your email.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LxpButton, LxpInput, LxpLoader } from "didactica";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";

import GeneralInformation from "../Shared/GeneralInformation.vue";

export default {
  components: {
    LxpButton,
    LxpInput,
    LxpLoader,
    ValidationObserver,
    ValidationProvider,
    GeneralInformation
  },
  data() {
    return {
      isEdit: false,
      isChangePassword: false,
      password: {
        new_password: "",
        re_new_password: ""
      },
      passwordLoadingStatus: "INIT"
    };
  },
  computed: {
    ...mapGetters(["userAccountInfo"]),
    info() {
      let userObj = {};
      if (this.userAccountInfo) {
        userObj = this.userAccountInfo;
      }
      return userObj;
    },
    passwordContent() {
      if (this.isChangePassword) {
        if (this.passwordLoadingStatus === "SUCCESS") {
          return `<span>
          <img src="https://whoalxppublicstorage.blob.core.windows.net/didactica/assets/images/checkbox/tick-white.svg" width="16" height="16" style="margin-right:8px;"/>
          Saved</span>`;
        } else {
          return "Save";
        }
      } else {
        return "Change Password";
      }
    },
    btnClassName() {
      if (this.passwordLoadingStatus === "LOADING") {
        return "sw-account__password-loading";
      } else {
        return "";
      }
    }
  },
  methods: {
    onSave(data) {
      this.$store.dispatch("updateUserAccountInfo", data).then(() => {
        this.$refs["account-general-info"].edit = false;
      });
    },
    onChangePassword() {
      if (this.isChangePassword) {
        this.$refs["change-password-observer"].validate().then(res => {
          if (res) {
            this.passwordLoadingStatus = "LOADING";
            this.$store
              .dispatch("resetPassword", this.password)
              .then(() => {
                this.passwordLoadingStatus = "SUCCESS";
                setTimeout(() => {
                  this.isChangePassword = false;
                }, 200);
              })
              .catch(err => {
                this.passwordLoadingStatus = "ERROR";
                if (Object.keys(err).includes("non_field_errors")) {
                  this.$refs["change-password-observer"].setErrors({
                    password: err["non_field_errors"]
                  });
                } else {
                  this.$refs["change-password-observer"].setErrors(err);
                }
              });
          }
        });
      } else {
        this.isChangePassword = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sw-account {
  .sw-account__password {
    @include account-style();
    .sw-account__password--heading {
      @include horizontal-space-between;
      margin-bottom: 8px;
      .sw-account__password-loading {
        background: $brand-secondary1-600;
      }
    }
    p {
      @include body-medium;
      color: $brand-neutral-700;
      margin-bottom: 0;
    }
    .sw-account__password--form {
      display: flex;
      span {
        width: 100%;
        display: flex;
        .sw-account__password--form__div {
          width: 33%;
          margin-right: 16px;
          .lxp-input {
            width: 98%;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.sw-account {
  .sw-account__password--loader {
    .spinner-border {
      color: $brand-neutral-0 !important;
      width: 24px;
      height: 24px;
      border-width: 2px;
    }
  }
}
</style>
